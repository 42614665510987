@use '../abstracts/variables'as vars;
@use "sass:map";

.calendar-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar{
    width: 50%;
    max-width: 550px;
    min-width: 400px;
    min-height: 400px;
}

.month{
    width: 60%;
    margin: 0 auto;
    display: flex;
    text-align: center;

    h3{
        font-weight: 800;
        width: 190px;
    }
    i{
        font-size: 24px;
        color: white;
        cursor: pointer;
        &:hover{
            color: map.get($map: vars.$colors, $key: selected);
            transition: 0.5s;
        }
    }
}

.weekdays{
    width: 100%;
    display: grid;
    margin-bottom: 2em;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    font-weight: 800;

}

.days{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    .prev-date, .next-date{
        opacity: 0.5;
    }

    .day:hover{
       border: 1px solid #D9C2FB;
       border-radius: 5px;
    }
    div{
       
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 16px;

        .event-in-calendar{
            min-height: 3em;
            display: flex;
            flex-wrap: wrap;
            gap: 0.3em;
            padding: 0.5em;
            .dot-event{
                width: 10px;
                height: 10px;
                border-radius: 100%;
            }

            .invitation{
                background-color: #AB4BFE;
            }

            .accepted{
                background-color: #5246F0;
            }
        }

    }
}

.today{
    color: #7B7BF5;
    font-weight: 800;
}