@use '../abstracts/variables'as vars;
@use "sass:map";

.register-login-form{
    max-width: 700px;
    margin: 0 auto;
    label{
        display: block;
        margin-bottom: 0.5em;
    }
    input{
        display: block;
        margin-bottom: 2em;
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: none;
        background-color: map.get($map: vars.$colors, $key: inputfields);
        color: map.get($map: vars.$colors, $key: white);
        outline: none;
        padding: 0 1em;
    }

    .primair{
        display: block;
        margin: 0 auto;
    }
}