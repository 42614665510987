@use '../abstracts/variables'as vars;
@use "sass:map";
@use '../abstracts/mediaqueries-mixins'as media;


.content-acount-page{
    margin-top: 7em;

    @include media.for-tablet-portrait-up {
        margin-top: 2em;
    }

    p{
        margin-bottom: 2em;
        cursor: pointer;
    }

    .warning-text{
        color: map.get($map: vars.$colors, $key: warning-color);
    }
}