@use '../abstracts/variables'as vars;
@use "sass:map";
@use '../abstracts/mediaqueries-mixins'as media;

.header-account{
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2;
    gap: 2em;
    align-items: baseline;
    padding: 1em;
    background-color: map.get($map: vars.$colors, $key: nav-background-color);
    @include media.for-tablet-portrait-up {
        border-top: 3px solid rgba(255, 255, 255, 0.253);
        padding: 1em 4em;
    }

    // arrow icon to go to the previous page
    i{
        font-size: 24px;
        &:hover{
            color: map.get($map: vars.$colors, $key: selected);
            cursor: pointer;
            transition: 0.3s;
        }
    }
}