.beginscreen{
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    max-width: 360px;

    h1{
        padding: 0;
        margin: 0 auto;
        text-align: center;
        font-size: 48px;
        
    }

    button{
        display: block;
        margin: 0 auto;
    }

    button:first-of-type{
        margin-top: 5em;

    }
    button+button{
        margin-top: 2em;
    }
}