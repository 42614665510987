@use '../abstracts/variables'as vars;
@use "sass:map";
@use '../abstracts/extends';
@use '../abstracts/mediaqueries-mixins'as media;

.event-content{

    h2{
        padding-top: 3em;
    }

    h3{
        margin-top: 2em;
    }

    .map-div-location{
        background-color: fuchsia;
        width: 100%;
        height: 160px;
        margin-bottom: 1em;
    }

    .location-text{
        text-align: center;
    }

    .div-person{
        display: flex;
        gap: 2em;
        align-items: center;
        .avatar-creator{
            width: 64px;
            height: 64px;
            border-radius: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}