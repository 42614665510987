@use '../abstracts/variables' as vars;
@use "sass:map";
.flex{
    display: flex;
}

.selected{
    color: map.get($map: vars.$colors, $key: selected);
    p{
        color: map.get($map: vars.$colors, $key: selected);
    }
}

.active-link-nav{
    p, i{
        color: map.get($map: vars.$colors, $key: selected);
    }
    
}

.hide{
    display: none;
}

.error{
    color: map.get($map: vars.$colors, $key: warning-color);
}