@use '../abstracts/variables'as vars;
@use "sass:map";

h1,h2,h3,h4,h5,h6,p{
    color: map.get($map: vars.$colors, $key: white);
    font-family: vars.$font-family;
    margin-bottom: 1em;
}

p{
    margin-bottom: 1em;
}

a{
    color: map.get($map: vars.$colors, $key: tertiary-color);
    text-decoration: underline;

    &:hover{
        color: rgba(255, 255, 255, 0.383);
        cursor: pointer;
    }
}

div{
    color: map.get($map: vars.$colors, $key: white);
    font-family: vars.$font-family;
}
  