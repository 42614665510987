$colors: (
    background-color: #1d2032,
    nav-background-color: #0e101b,
    white: #FFF,
    selected: #815ed0,
    primair-color: #5555c5,
    secundary-color: #fff,
    tertiary-color: #649cd9,
    directions-color: #192563,
    popup-background-color: #191f3e,
    inputfileds-popup: #2a3153,
    inputfields: #31354c,
    safe: #4D22DD,
    warning-color: #ff4a4a,
    location-event-color: #d23434, 
    all-filter: #5246F0,
    accepted-filter: #7562F8,
    invitations: #AB4BFE
);



$font-family: 'Lato', sans-serif;

$line-color: rgba(255, 255, 255, 0.300);
