@use '../abstracts/variables'as vars;
@use "sass:map";
@use '../abstracts/mediaqueries-mixins'as media;

.content-spa .nav-container{
    padding: 1em 15%;
}
.nav-container{
    z-index: 3;
    padding: 1em 5%;
    background-color: map.get($map: vars.$colors, $key: nav-background-color);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    @include media.for-tablet-portrait-up {
        position: relative;
    }
}


nav {
    @include media.for-tablet-portrait-up {
        display: flex;
        justify-content: space-between;
    }
    .logo{
        display: none;
        @include media.for-tablet-portrait-up {
            display: block;
        }
    }
    ul {
        padding: 0;
        display: flex;
        justify-content: space-between;
        margin: 0;

        @include media.for-tablet-portrait-up {
           gap: 2em;
        }

        li {
            list-style: none;

            a {
                text-decoration: none;
                color: map.get($map: vars.$colors, $key: white);
                .icon{
                    text-align: center;
                    margin-bottom: 0.5em;
                    font-size: 24px;

                    @include media.for-tablet-portrait-up {
                        display: none;
                    }
                }
            }

            &:hover div, &:hover p{
                color: map.get($map: vars.$colors, $key: selected);
            }

        }
    }
}