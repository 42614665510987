.content-spa{

    // _____btns on the map page_________
    .btns-div-map{
        position: fixed;
        bottom: 20%;
        right: 2em;
        z-index: 2;

        button{
            display: block;
        }

        button+button{
            margin-top: 2em;
        }
    }

    .warning-top{
        z-index: 1;
        
    }


    //__________mapbox_________
    .marker {
        background-image: url('https://prachtigparijs.nl/images/eiffeltoren.jpg');
        background-size: cover;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
      }

      .mapboxgl-popup {
        max-width: 200px;
      }
      
      .mapboxgl-popup-content {
        text-align: center;
        font-family: 'Open Sans', sans-serif;
        h3,p{
            color: rgb(62, 6, 165);
        }
      }
      

    // ______change mapbox default styles____________

    .mapbox-directions-inputs{
        display: none;
    }

    .directions-control-instructions{
        background-color: rgba(74, 57, 119, 0.781);
    }

    .mapbox-directions-route-summary{
        position: fixed;
        background-color: #374c8f;
    }

    .mapbox-directions-steps{
        margin-top: 2em;
    }

}